

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS } from '../../location-availability-filters.service';

@Component({
    components: { CustomMultiSelect, StyledSearchbar },
})
export default class LocationAvailabilityPickUpCityFilter extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get query() {
        return this.lAvailabilityFiltersService.locationQuery;
    }

    set query(value) {
        this.lAvailabilityFiltersService.locationQuery = value;
        this.currentValue = this.items.filter(item => !item.disabled);
    }

    get items() {
        const { country, pos } = this.lAvailabilityFiltersService;
        const { allowedPosCodeLocations, carLocations } = this.carsSharedService.filters;
        if (!country || !pos) {
            return [];
        }

        let locations = this.lAvailabilityFiltersService.getLocationsBySelectedCountry(country, this.query);

        if (!locations) {
            return [];
        }

        locations = locations.filter(loc => _.get(allowedPosCodeLocations, `${pos}.${loc.locationId}`));

        if (carLocations && carLocations.length) {
            const countryOnlyLocationId = carLocations.map(item => item.locationId);
            locations = locations.filter(item => countryOnlyLocationId.includes(item.locationId));
        }

        const result = locations.map(loc => ({
            name: loc.locationName,
            value: loc.locationId,
            disabled: false,
        })).sort((a, b) => {
            if (a.name <= b.name) {
                return -1;
            }
            return 1;
        });

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        this.lAvailabilityFiltersService.allowedPickUpLocationIds = result.map(location => location.value);

        return result;
    }

    get currentValue() {
        return this.carsSharedService.isLocationsFilterLoaded() && this.lAvailabilityFiltersService.storeState.settings.pickUpCityCodes
            ? this.lAvailabilityFiltersService.storeState.settings.pickUpCityCodes
                .map(locationId => {
                    const loc = this.carsSharedService.getLocationNameById(locationId) || { locationId: '', locationName: '' };
                    return {
                        value: loc.locationId || '',
                        name: loc.locationName,
                    };
                })
            : [];
    }

    set currentValue(values) {
        this.carsSharedService.savePickUpCitiesAndCountry(values.map(loc => loc!.value), this.$route.path);
    }

    get searchDefaultValue() {
        return this.lAvailabilityFiltersService.locationQuery;
    }

    handleChange(query: string) {
        this.query = query;
    }
}
